import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Div from "@components/Div";
import Card from "@components/CardV2";
import {
  Text,
  TextMediumWeight,
  TextUpperCase,
  TextSemiBoldWeight,
} from "@components/Text";
import Span from "@components/Span";
import Link, { LinkArrow } from "@components/Link";
import { PrimaryButtonIcon } from "@components/Button";
import Icon from "@components/Icon";
import { default as Accordion } from "@components/Accordion";
import useAuthorization from "@hooks/useAuthorization";
import { AdminPermissions } from "@src/enum/Permissions";
import {
  ADMIN_CUSTOMER_DETAILS,
  ROUTES,
  STATUS_COMPLETED,
} from "@utils/constant";

import styled from "styled-components";

import Config from "../../../config";
import PlaceholderForContent from "./PlaceholderForContent";

const StyledCard = styled(Card)`
  cursor: pointer;

  .p-card-header {
    min-height: 59px;
  }
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
`;

const StyledDescription = styled.div`
  font-weight: 300;
  color: var(--grey-dark);

  strong {
    font-weight: var(--semibold-weight);
  }

  em {
    font-style: italic;
  }

  ul {
    list-style: disc;

    li {
      margin-top: 8px;
      margin-left: 24px;
    }
  }
`;

const StyledAccordion = styled(Accordion)`
  .p-accordion-tab {
    display: flex;
    flex-direction: column-reverse;

    .p-accordion-content {
      padding-top: 0px !important;
    }

    .p-accordion-toggle-icon {
      margin-left: 5px;
      height: 12px;
      width: auto;
    }
  }
`;

const DetailsTab = ({
  assignment,
  order,
  isExpired,
  onToggleAssigneeDialog,
  onToggleCompleteAssignmentDialog,
}) => {
  const { messages } = useIntl();
  const history = useHistory();
  const { hasAllPermissions } = useAuthorization();
  const hasWriteAccessToAssignments = hasAllPermissions([
    AdminPermissions.AdminWriteAssignments,
  ]);

  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    product = {},
    user = {},
    order_item: orderItem = {},
    reports = [],
    documents = [],
  } = assignment;

  const isReport = documents?.length;
  const reportID = isReport ? reports[0]?.id : "";
  const reportLink = `${ROUTES.ORDER_RESULTS.URL}?id=${reportID}`;

  const redirectToOrderDetail = () => {
    const { order_id: orderId = "" } = assignment;
    history.push(`${ROUTES.ADMIN_ORDER_DETAILS.URL}?id=${orderId}`);
  };

  const redirectToCustomerDetail = () => {
    const { customer_id: customerId } = order || {};
    history.push(
      `${ROUTES.ADMIN_CUSTOMERS.URL}/${customerId}/${ADMIN_CUSTOMER_DETAILS}`,
    );
  };

  const redirectToReport = () => {
    history.push(reportLink);
  };

  const renderDescription = (serviceDescription) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(serviceDescription, "text/html");
    const isDescriptionInHTML = Array.from(doc.body.childNodes).some(
      (node) => node.nodeType === 1,
    );

    if (isDescriptionInHTML) {
      const firstParagraph = doc.body.firstChild;

      const isNeedToShowAccordion =
        firstParagraph.textContent.split(".").length > 3 ||
        Array.from(doc.body.childNodes).filter((node) => node.nodeType === 1)
          .length > 3;

      return (
        <PlaceholderForContent
          isLoading={isLoading}
          ml={10}
          width="100%"
          height="36px"
        >
          {isNeedToShowAccordion ? (
            <Div>
              {!isDescriptionExpanded && (
                <StyledDescription
                  dangerouslySetInnerHTML={{
                    __html: firstParagraph?.outerHTML,
                  }}
                />
              )}
              <StyledAccordion
                activeIndex={+!isDescriptionExpanded}
                onTabChange={() => {
                  setIsDescriptionExpanded(!isDescriptionExpanded);
                }}
                content={[
                  {
                    title: isDescriptionExpanded
                      ? messages.label_less
                      : messages.label_more,
                    content: (
                      <StyledDescription
                        dangerouslySetInnerHTML={{ __html: serviceDescription }}
                      />
                    ),
                  },
                ]}
              />
            </Div>
          ) : (
            <StyledDescription
              dangerouslySetInnerHTML={{ __html: serviceDescription }}
            />
          )}
        </PlaceholderForContent>
      );
    } else {
      return (
        <PlaceholderForContent
          isLoading={isLoading}
          ml={10}
          width="100%"
          height="36px"
        >
          <TextMediumWeight>{serviceDescription}</TextMediumWeight>
        </PlaceholderForContent>
      );
    }
  };

  useEffect(() => {
    setIsLoading(!Object.keys(assignment)?.length);
  }, [assignment]);

  const handleOpenSetAssigneeDialog = () => {
    if (isExpired || assignment.status === STATUS_COMPLETED) {
      return;
    }

    onToggleAssigneeDialog();
  };

  return (
    <Div>
      <Div
        display={["block", "flex"]}
        width={[1, "70%"]}
        justifyContent="space-between"
        alignItems="center"
      >
        <Link fontSize="18px" onClick={redirectToCustomerDetail}>
          <TextSemiBoldWeight>{`${messages.label_customer}:`}</TextSemiBoldWeight>
          <PlaceholderForContent
            isLoading={isLoading}
            ml={10}
            width="100px"
            height="18px"
          >
            <Span
              info
              large
              ml={10}
              fontWeight="var(--light-weight) !important"
            >{`${order?.customer?.customer_name ?? ""}`}</Span>
          </PlaceholderForContent>
        </Link>

        <Link mt={[16, 0]} fontSize="18px" onClick={redirectToOrderDetail}>
          <TextSemiBoldWeight>{`${messages.label_order}:`}</TextSemiBoldWeight>
          <PlaceholderForContent
            isLoading={isLoading}
            ml={10}
            width="100px"
            height="18px"
          >
            <Span
              info
              large
              ml={10}
              fontWeight="var(--light-weight) !important"
            >{`#${order?.order_id || ""}`}</Span>
          </PlaceholderForContent>
        </Link>
      </Div>

      <Div mt={16} minWidth="30%">
        <TextSemiBoldWeight display="flex">
          {`${messages.title_object_name}:`}
          <PlaceholderForContent
            isLoading={isLoading}
            ml={10}
            width="100px"
            height="18px"
          >
            <Text ml={10}>{orderItem.person_name}</Text>
          </PlaceholderForContent>
        </TextSemiBoldWeight>
      </Div>

      <Div mt={16} display="flex" flexDirection="column">
        <TextSemiBoldWeight>
          {`${messages.label_service_description}:`}
        </TextSemiBoldWeight>
        <Div mt={3} />
        {renderDescription(product?.description)}
      </Div>

      <StyledCard
        disabled={!hasWriteAccessToAssignments}
        mt={16}
        onClick={handleOpenSetAssigneeDialog}
      >
        <Div
          display={"flex"}
          flexDirection={["column", "row"]}
          justifyContent={"space-between"}
          alignItems={["flex-start", "center"]}
        >
          <TextSemiBoldWeight>{`${messages.label_assignee}:`}</TextSemiBoldWeight>
          <Div display="flex" mt={[10, 0]}>
            <PlaceholderForContent
              isLoading={isLoading}
              ml={10}
              width="120px"
              height="18px"
            >
              {user?.name ? (
                <Div
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Icon
                    name="assignee"
                    fontSize="20px"
                    color="var(--turquoise)"
                  />
                  <TextMediumWeight ml={1}>{`${user?.name}`}</TextMediumWeight>
                </Div>
              ) : (
                <TextUpperCase color="var(--red) !important">
                  {messages.not_assigned}
                </TextUpperCase>
              )}
            </PlaceholderForContent>

            <Div
              ml={4}
              backgroundColor="var(--blue-lightest)"
              padding={2}
              borderRadius={5}
              width={20}
              height={20}
              display={"flex"}
              justifyContent="center"
              alignItems="center"
            >
              <Icon name="pen" fontSize="12px" color="var(--blue-dark)" />
            </Div>
          </Div>
        </Div>
      </StyledCard>

      {!isExpired && (
        <StyledCard
          mt={3}
          header={
            <TextSemiBoldWeight py={0} display="flex">
              <PlaceholderForContent
                isLoading={isLoading}
                ml={10}
                width="50%"
                height="18px"
              >
                {isReport
                  ? messages.label_assignment_is_completed
                  : messages.title_complete_assignments}
              </PlaceholderForContent>
            </TextSemiBoldWeight>
          }
        >
          <Div>
            <PlaceholderForContent
              isLoading={isLoading}
              width="50%"
              height="36px"
              display="flex"
              flexDirection="column"
            >
              <Div display="flex" flexDirection="column">
                <Text>
                  {isReport
                    ? messages.text_assignment_is_completed
                    : messages.text_attach_report}
                </Text>
                {isReport ? (
                  <Div
                    mt={3}
                    display="flex"
                    alignSelf="flex-start"
                    alignItems="center"
                    gridGap={4}
                  >
                    <LinkArrow
                      label={messages.see_report}
                      direction="right"
                      variant="secondary"
                      handleClick={redirectToReport}
                    >
                      <Icon ml={1} name="headerarrowright" />
                    </LinkArrow>
                    <CopyToClipboard text={`${Config.APP_URL}${reportLink}`}>
                      <LinkArrow
                        label={messages.label_copy_report_link}
                        iconPos="left"
                      >
                        <Icon mr={1} name="copy" />
                      </LinkArrow>
                    </CopyToClipboard>
                  </Div>
                ) : (
                  <PrimaryButtonIcon
                    width={245}
                    mt={16}
                    rounded
                    semibold
                    height={40}
                    px={22}
                    icon={<Icon name="file-download" mr={2} />}
                    label={messages.label_complete_assignments}
                    disabled={!hasWriteAccessToAssignments || !assignment?.user}
                    onClick={onToggleCompleteAssignmentDialog}
                  />
                )}
              </Div>
            </PlaceholderForContent>
          </Div>
        </StyledCard>
      )}
    </Div>
  );
};

DetailsTab.propTypes = {
  assignment: PropTypes.object,
  order: PropTypes.object,
  isExpired: PropTypes.bool,
  onToggleAssigneeDialog: PropTypes.func,
  onToggleCompleteAssignmentDialog: PropTypes.func,
};

export default DetailsTab;
