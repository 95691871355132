import React from "react";
import PropTypes from "prop-types";

import Card from "@components/Card";
import Div from "@components/Div";
import { H4 } from "@components/Heading";

const CardWrapper = ({ title, children }) => {
  return (
    <Card p={3} borderRadius={20} mb={3}>
      <H4>{title}</H4>

      <Div
        mt={3}
        width="100%"
        height={1}
        backgroundColor="var(--grey-lightest)"
      />

      <Div
        display="flex"
        flexDirection={["column", "column", "row", "row"]}
        flexWrap={["nowrap", "nowrap", "wrap", "wrap"]}
        gridGap={3}
      >
        {children}
      </Div>
    </Card>
  );
};

CardWrapper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default CardWrapper;
