import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { useFormik } from "formik";

import getPermissionsService from "@app/services/users/getPermissionsService";
import getRolesByTypeService from "@app/services/users/getRolesByTypeService";
import inviteUserService from "@app/services/users/inviteUserService";
import updateUserService from "@app/services/users/updateUserService";

import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import Div from "@components/Div";
import { InputCheckGroup, InputDropdown } from "@components/Input";
import InputText from "@components/InputText";
import ProgressSpinner from "@components/ProgressSpinner";
import { Text } from "@components/Text";

import { PERMISSIONS } from "@utils/constant";
import { ROLE_TYPE, USER_TYPE } from "@utils/enum";
import { enumValueToTranslationKey } from "@utils/utils";

import InviteUserSchema from "./InviteUserSchema";
import EditUserSchema from "./EditUserSchema";

import CardWrapper from "./CardWrapper";
import { useToast } from "@hooks/useToast";

import {
  formatRolesDataPermissions,
  getSelectedPermissions,
  determineRoleToPick,
  updateSelectedPermissions,
  formatPermissionsToFormStructure,
  getPermissionsByGroup,
  getPermissionsByRole,
} from "./utils";
import SelectCustomer from "./SelectCustomer";

const INVITE_INITIAL_VALUES = {
  customer_id: "",
  email: "",
  role: "",
  permissions: {},
};

const Form = ({ customerId, userType, userDetails, onClose }) => {
  const { messages } = useIntl();

  const [isLoading, setIsLoading] = useState(false);
  const [rolesData, setRolesData] = useState([]);
  const [permissionsData, setPermissionsData] = useState([]);
  const { showSuccessToast, showErrorToast } = useToast();

  const isEditMode = !!userDetails;
  const shouldShowSelectCustomer = !(customerId || userDetails?.customer_id) && userType === USER_TYPE.CUSTOMER;

  const fetchRoles = useCallback(async () => {
    try {
      setIsLoading(true);

      const { data = [] } = (await getRolesByTypeService(userType)) ?? {};
      const formattedData = data.map(({ name, ...rest }) => ({
        ...rest,
        value: name,
        name: messages[enumValueToTranslationKey(name)],
      }));

      return formattedData;
    } catch (error) {
      showErrorToast(messages.exception_error_message);
    } finally {
      setIsLoading(false);
    }
  }, [messages, userType, showErrorToast]);

  const fetchPermissions = useCallback(async () => {
    try {
      setIsLoading(true);

      const { data: permissions = [] } =
        (await getPermissionsService(userType)) ?? {};
      const mappedPermissions = permissions.map(({ name, group, ...rest }) => ({
        ...rest,
        group: group,
        value: name,
        label: messages[enumValueToTranslationKey(name)],
        groupName: messages[`permissions_group_${group}`],
      }));

      return mappedPermissions;
    } catch (error) {
      showErrorToast(messages.exception_error_message);
    } finally {
      setIsLoading(false);
    }
  }, [messages, userType, showErrorToast]);

  useEffect(() => {
    const fetchDetails = async () => {
      setIsLoading(true);

      try {
        const [roles, permissions] = await Promise.all([
          fetchRoles(),
          fetchPermissions(),
        ]);
        setRolesData(roles);
        setPermissionsData(permissions);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDetails();
  }, [fetchRoles, fetchPermissions, messages, showErrorToast]);

  const initialValues = useMemo(() => {
    if (!userDetails) {
      return {...INVITE_INITIAL_VALUES, customer_id: customerId};
    }

    const {
      firstname: firstName,
      lastname: lastName,
      email,
      phone,
      roles: [selectedRole = {}] = [],
      permissions: userPermissions = [],
      customer_id = "",
    } = userDetails;
    const { name: selectedRoleName, permissions: rolePermissions = [] } =
      selectedRole;
    const isCustomRole =
      selectedRoleName === ROLE_TYPE.CUSTOM ||
      selectedRoleName === ROLE_TYPE.ADMIN_CUSTOM;
    const selectedUserPermissions = isCustomRole
      ? userPermissions
      : rolePermissions;
    const formattedPermissions = formatPermissionsToFormStructure(
      getPermissionsByGroup(permissionsData),
      selectedUserPermissions,
    );

    return {
      firstName,
      lastName,
      email,
      phone,
      role: selectedRoleName,
      permissions: formattedPermissions,
      customer_id,
    };
  }, [customerId, userDetails, permissionsData]);

  const validationSchema = useMemo(
    () => (isEditMode ? EditUserSchema : InviteUserSchema),
    [isEditMode],
  );

  const handleInviteUser = useCallback(
    async ({ type, customer_id, ...values }) => {
      const isSubUser = type === USER_TYPE.CUSTOMER;
      const payload = {
        ...values,
        type,
      };

      if (isSubUser) {
        payload.customer_id = customer_id;
      }

      return await inviteUserService(payload);
    },
    [],
  );

  const handleUpdateUser = useCallback(
    async ({ type, firstName, lastName, customer_id, ...values }) => {
      const { id: userId, status } = userDetails;
      const isSubUser = type === USER_TYPE.CUSTOMER;
      const isActiveUser = status === "active";
      const payload = {
        ...values,
        type,
      };

      if (isSubUser) {
        payload.customer_id = customer_id;
      }

      if (isActiveUser) {
        payload.firstname = firstName;
        payload.lastname = lastName;
      }

      return await updateUserService(payload, userId);
    },
    [userDetails],
  );

  const handleSubmit = useCallback(
    async ({ permissions = [], customer_id, ...values }) => {
      const formattedPermissions = Object.values(permissions)
        .flatMap((permissionsCategory) => Object.entries(permissionsCategory))
        .filter(([, value]) => value)
        .map(([key]) => key);
      const payload = {
        ...values,
        type: userType,
        permissions: formattedPermissions,
        customer_id: typeof customer_id === "object" ? customer_id?.id : customer_id,
      };

      try {
        setIsLoading(true);

        let response = null;

        if (isEditMode) {
          response = await handleUpdateUser(payload);
        } else {
          response = await handleInviteUser(payload);
        }

        if (!response) {
          showErrorToast(messages.exception_error_message);
          return;
        }

        const message = isEditMode
          ? messages.title_user_updated
          : messages.title_user_invited;

        showSuccessToast(message, messages.confirmaton);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    },
    [
      handleInviteUser,
      handleUpdateUser,
      isEditMode,
      messages,
      userType,
      showErrorToast,
      showSuccessToast,
    ],
  );

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: handleSubmit,
  });

  const renderEditContent = () => (
    <>
      <Div flex="1 1 40%">
        <InputText
          width={1}
          formikProps={formik}
          name="firstName"
          label={messages.label_firstname}
          placeholder={`${messages.label_firstname}`}
          value={formik.values.firstName}
          onChange={formik.handleChange}
        />
      </Div>

      <Div flex="1 1 40%">
        <InputText
          width={1}
          formikProps={formik}
          name="lastName"
          label={messages.label_surname}
          placeholder={`${messages.label_surname}`}
          value={formik.values.lastName}
          onChange={formik.handleChange}
        />
      </Div>

      <Div flex="1 1 40%">
        <InputText
          width={1}
          formikProps={formik}
          name="email"
          label={messages.label_email}
          placeholder={`${messages.label_email}`}
          value={formik.values.email}
          onChange={formik.handleChange}
        />
      </Div>

      <Div flex="1 1 40%">
        <InputText
          width={1}
          formikProps={formik}
          name="phone"
          label={messages.label_phone}
          placeholder={`${messages.label_phone}`}
          value={formik.values.phone}
          onChange={formik.handleChange}
        />
      </Div>
    </>
  );

  const renderInviteContent = () => (
    <>
      <Div mt={3} width={1}>
        <Text>{messages.message_enter_invite_email}</Text>
      </Div>

      <Div flex="1 1 40%">
        <InputText
          width={1}
          formikProps={formik}
          name="email"
          label={messages.label_email}
          placeholder={`${messages.placeholder_billing_email}...`}
          value={formik.values.email}
          onChange={formik.handleChange}
        />
      </Div>
      <Div flex="1 1 40%" />
    </>
  );

  const contentToRender = !isEditMode ? renderInviteContent : renderEditContent;

  const handleChangeRole = (event) => {
    const { value: selectedRole } = event;
    const selectedRolePermissions = getPermissionsByRole(
      selectedRole,
      rolesData,
    );
    const formattedPermissions = formatPermissionsToFormStructure(
      getPermissionsByGroup(permissionsData),
      selectedRolePermissions,
    );

    formik.setFieldValue(PERMISSIONS, formattedPermissions);
    formik.handleChange(event);
  };

  const handleChangePermissions = (event) => {
    formik.handleChange(event);

    const { checked, target: { name = "" } = {} } = event;
    const selectedPermissions = getSelectedPermissions(
      formik.values.permissions,
    );
    const pickedPermission = name.replace(/permissions\.\w+\./, "");

    updateSelectedPermissions(selectedPermissions, pickedPermission, checked);

    const rolesPermissions = formatRolesDataPermissions(rolesData);
    const roleToPick = determineRoleToPick(
      rolesPermissions,
      selectedPermissions,
      userType,
    );

    formik.setFieldValue("role", roleToPick);
  };

  useEffect(()=>{
    if(Object.keys(formik.values.permissions).length > 0){
      for (let key in formik.values.permissions) {
        for (let keyRole in formik.values.permissions[key]) {
          let value = formik.values.permissions[key][keyRole];
          if (Array.isArray(value)) {
            formik.values.permissions[key][keyRole] = value.includes("on");
          }
        }
      }
    }
  },[formik.values.permissions])
  

  const renderRolesAndPermissions = () => {
    const permissionsArr = Object.entries(
      getPermissionsByGroup(permissionsData),
    );
    const midIndex = Math.ceil(permissionsArr.length / 2);
    const firstHalf = permissionsArr.slice(0, midIndex);
    const secondHalf = permissionsArr.slice(midIndex);

    return (
      <Div
        display="flex"
        flexDirection={["column", "column", "row", "row"]}
        flexWrap={["nowrap", "nowrap", "wrap", "wrap"]}
        gridGap={3}
        width={1}
      >
        <InputDropdown
          flex="1 1 40%"
          width={1}
          formikProps={formik}
          name="role"
          label={messages.role_label}
          placeholder={messages.placeholder_choose}
          value={formik.values.role}
          onChange={handleChangeRole}
          options={rolesData}
          optionLabel="name"
        />
        <Div flex="1 1 40%" />

        <Div
          display="flex"
          flexDirection={["column", "column", "row", "row"]}
          gridGap={[0, 0, 3, 3]}
          width={1}
        >
          <Div width={1}>
            {firstHalf.map(([key, value]) => (
              <InputCheckGroup
                key={`permissions.${key}`}
                groupName={key}
                formikProps={formik}
                name={`permissions.${key}`}
                options={value.options}
                label={value.title}
                onChange={handleChangePermissions}
                value={formik.values.permissions}
              />
            ))}
          </Div>

          <Div width={1}>
            {secondHalf.map(([key, value]) => (
              <InputCheckGroup
                key={`permissions.${key}`}
                groupName={key}
                formikProps={formik}
                name={`permissions.${key}`}
                options={value.options}
                label={value.title}
                onChange={handleChangePermissions}
                value={formik.values.permissions}
              />
            ))}
          </Div>
        </Div>
      </Div>
    );
  };

  return (
    <Div display="flex" flexDirection="column" >
      {isLoading && <ProgressSpinner />}

      {shouldShowSelectCustomer && (
        <CardWrapper title={messages.header_select_customer}>
          <SelectCustomer formik={formik}/>
        </CardWrapper>
      )}

      <CardWrapper title={messages.title_user_details}>
        {contentToRender()}
      </CardWrapper>

      <CardWrapper title={messages.title_role_and_permissions}>
        {renderRolesAndPermissions()}
      </CardWrapper>

      <Div
        display="flex"
        flexDirection={["column", "column", "row", "row"]}
        alignItems="center"
        gridGap={4}
      >
        <PrimaryButtonOutlined
          width={[1, 1, "150px", "150px"]}
          label={messages.label_cancel}
          onClick={onClose}
        />
        <PrimaryButton
          width={[1, 1, "150px", "150px"]}
          label={messages.label_save}
          disabled={!formik.dirty}
          onClick={formik.handleSubmit}
        />
      </Div>
    </Div>
  );
};

Form.propTypes = {
  customerId: PropTypes.string,
  userType: PropTypes.string,
  userDetails: PropTypes.object,
  onClose: PropTypes.func,
};

export default Form;
